<template>
	<div class="order">
		{{data.content_chinese}}
	</div>
</template>

<script>
	export default {
		props:['data'],
		name: "order",
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		created() {
			
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.order{
		width: 1029px;
		padding: 20px 24px;
		background: #FFFFFF;
		border-radius: 12px;
		margin-bottom: 8px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		box-sizing: border-box;
	}
</style>