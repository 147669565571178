<template>
	<div class="message">
		<div class="title">
			<div class="txt">
				{{i18n('281')}}
			</div>
			<div class="gang">
				/
			</div>
			<div class="tab">
				<div :class="type==2?'item itemAc':'item'" @click="typeChange(2)">
					{{i18n('282')}}
				</div>
				<div :class="type==1?'item itemAc':'item'" @click="typeChange(1)">
					{{i18n('283')}}
				</div>
			</div>
		</div>
		<div class="inner">
			<div class="" v-if="type==2">
				<order v-for="(item,index) in listData" :key="index" :data='item'></order>
			</div>
			<div class="" v-if="type==1">
				<yh v-for="(item,index) in listData" :key="index" :data='item'></yh>
			</div>
			<div class="noData" v-if="noData">
				<div class="">
					<img src="@/assets/noData2.png" alt="">
					<div class="text">
						{{i18n('284')}}
					</div>
				</div>
			</div>

			<div class="pagination" v-if="total!=0">
				<el-pagination layout="prev, pager, next" :total="total" :page-size="params.page_size"
					:current-page='params.page_num' @current-change="currentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		orderMessageList
	} from "@/api/order.js"
	import {
		noticeList
	} from "@/api/content.js"

	import order from "./components/order.vue"
	import yh from "./components/yh.vue"
	export default {
		name: "message",
		components: {
			order,
			yh
		},
		data() {
			return {
				params: {
					page_num: 1,
					page_size: 7
				},
				total:0,
				noData: false,
				listData: [],
				type: 2 //2订单   1优惠
			}
		},
		methods: {
			typeChange(type) {
				this.params.page_num = 1
				this.type = type
				this.listData=[]
				if (type == 2) {
					this.orderMessageList()
				} else {
					this.noticeList()
				}
			},
			currentChange(page_num) {
				this.params.page_num = page_num
				if (this.type == 2) {
					this.orderMessageList()
				} else {
					this.noticeList()
				}
			},
			async noticeList() {
				let params={
					page_num:this.params.page_num,
					page_size:this.params.page_size,
					type: 1,
					language: 0,
				}
				let lang = this.$store.state.i18n.lang
				if (lang == 'zh') {
					params.language = 0
				} else if (lang == 'en') {
					params.language = 1
				} else if (lang == 'vie') {
					params.language = 2
				}
				let {
					data,
					total
				} = await noticeList(params)
				this.listData = data
				this.total = total
				if (total == 0) {
					this.noData = true
				} else {
					this.noData = false
				}
			},
			async orderMessageList() {
				let _this= this;
				let {
					data,
					total
				} = await orderMessageList(this.params)
				if (total != 0) {
					data.map(item => {
						// 支付方式 0.未支付，1支付宝 2.微信 3.余额 4.Gpay 5.其它,6.GrabPay,7.Gcash,8.visa
						switch (item.pay_method) {
							case 1:
								item.pay_method = _this.i18n('200')
								break;
							case 2:
								item.pay_method = _this.i18n('201')
								break;
							case 3:
								item.pay_method = _this.i18n('203')
								break;
							case 4:
								item.pay_method = 'Gpay'
								break;
							case 6:
								item.pay_method = 'GrabPay'
								break;
							case 7:
								item.pay_method = 'Gcash'
								break;
							case 8:
								item.pay_method = 'visa'
								break;
							default:
								break;
						}
					})
				}
				this.listData = data
				this.total = total

				if (total == 0) {
					this.noData = true
				} else {
					this.noData = false
				}
			}
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo
			}
		},
		created() {
			this.orderMessageList()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.noData {
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.noData img {
		width: 100px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}

	.noData .text {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}

	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
		display: flex;
		align-items: center;
	}

	.title .gang {
		font-size: 12px;
		color: #ccc;
		line-height: 69px;
		padding: 0 8px;
	}

	.title .tab {
		display: flex;
		align-items: center;
	}

	.title .tab .item {
		margin-right: 16px;
		padding: 0 12px;
		height: 24px;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 4px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 24px;
		font-weight: 400;
		cursor: pointer;
	}

	.title .tab .itemAc {
		background: #56AB7B;
		color: #fff;
	}

	.message {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.inner {
		flex: 1;
		overflow-y: scroll;
	}

	>>>.el-pagination .btn-next {
		background: none;
	}

	>>>.el-pagination button:disabled {
		background: none;
	}

	>>>.el-pagination .btn-prev {
		background: none;
	}

	>>>.el-pager li.active {
		color: #56AB7B;
	}

	>>>.el-pager li {
		background: none;
	}

	.pagination {
		width: 100%;
		height: 36px;
		padding-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
